import React, { useState } from 'react'
import { Button, Col, Flex, List, message, Row, Typography } from 'antd';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { SiDpd } from "react-icons/si";

import styles from "../../Orders.module.scss";

import OrdersApi from '../../../../apis/order-management/OrdersApi';
import ListItem from '../ListItem';
import { getPayStatusByValue } from '../../util/common';
import StatusBadge from '../../../../components/StatusBadge';
import PayTypeBadge from '../../../../components/PayTypeBadge';

const { Text } = Typography;

export default function MoreInfo({ activeOrder, shippingCharge, priorityCharge, discount, baseUrl, setActiveOrder }) {
    const [loadingObj, setLoadingObj] = useState({
        exportRoyalMail: false,
        exportDPD: false,
        copyTrackingCode: false
    });
    function addressText(obj) {
        return `${obj.address}, ${obj.address_alt ? obj.address_alt : ""}${(obj?.address_alt && obj?.address_alt.length === 0) ? "" : ", "} ${obj.postal_code},  ${obj.city},  ${obj?.country?.name}.`;
    }

    const exportToRoyalMailHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            exportRoyalMail: true
        });

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.royalMailOrder(baseUrl, activeOrder.id);
            if (response) {
                message.success('Order was exported to Royal Mail!');
                setLoadingObj({
                    ...loadingObj,
                    exportRoyalMail: false
                });
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                exportRoyalMail: false
            });
        }
    }

    const exportToDPDHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            exportDPD: true
        });

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.dpdOrder(baseUrl, activeOrder.id);
            if (response) {
                message.success('Order was exported to DPD!');
                setLoadingObj({
                    ...loadingObj,
                    exportDPD: false
                });
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                exportDPD: false
            });
        }
    }

    const getRoyalMailOrderHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            copyTrackingCode: true
        });

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.getRoyalMailOrder(baseUrl, activeOrder.id);
            if (response) {
                copyTextToClipboard(response[0].trackingNumber);
                message.success('Royal Mail tracking code was copied!');
                setLoadingObj({
                    ...loadingObj,
                    copyTrackingCode: false
                });
            }
        }
        catch (e) {
            message.error((e.message || e.error) ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                copyTrackingCode: false
            });
        }
    }

    const copyTextToClipboard = (text) => {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const statusObj = getPayStatusByValue(activeOrder.is_paid);

    const handleUpdate = async (fieldType, newValue) => {
        if (activeOrder[fieldType] === newValue) return;

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const requestBody = {
                [fieldType]: newValue
            };
            const response = await OrdersApi.updateField(baseUrl, activeOrder.id, requestBody);

            if (response) {
                setActiveOrder(prevOrder => ({
                    ...prevOrder,
                    [fieldType]: newValue
                }));
                message.success(`${fieldType} Updated!`);
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        }
    };

    return (
        <Row gutter={16} className={styles.model_card_row}>
            <Col span={6}>
                <List
                    size="small"
                    header={<Text>Summary</Text>}
                    bordered
                    dataSource={[
                        <ListItem key={0}
                            keyText="Order # : "
                            valueText={activeOrder.order_no} valueCopyable />,
                        <ListItem key={1}
                            keyText="Payment method : "
                            valueText={(
                                <Flex>
                                    <StatusBadge value={statusObj.value} color={statusObj.color} label={statusObj.label} />
                                    <PayTypeBadge type={activeOrder?.payment_type} />
                                </Flex>
                            )}
                        />,
                        <ListItem key={2}
                            keyText="Rerun postage : "
                            valueText={(activeOrder.order_shipment && activeOrder?.order_shipment.is_shipping === 1) ? "£" + shippingCharge : "-"}
                        />,
                        <ListItem key={3}
                            keyText="Priority charge : "
                            valueText={(activeOrder.order_priority && activeOrder?.order_priority.is_priority === 1) ? "£" + priorityCharge : "-"}
                        />,
                        <ListItem key={4}
                            keyText="Discount : "
                            valueText={activeOrder.coupon ? "£" + discount : "-"}
                        />,
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </Col>

            <Col span={6}>
                <List
                    size="small"
                    header={<Text>Contact</Text>}
                    bordered
                    dataSource={[
                        <ListItem key={0}
                            keyText="Name : "
                            valueText={`${activeOrder.first_name} ${activeOrder.last_name ? activeOrder.last_name : ""}`}
                            valueCopyable
                        />,
                        <ListItem key={1}
                            keyText="E-Mail : "
                            valueText={activeOrder.email} valueCopyable
                            icon={<EditOutlined />}
                            fieldType="email"
                            editable={true}
                            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                        />,
                        <ListItem key={2}
                            keyText="Phone : "
                            valueText={activeOrder.phone_number} valueCopyable
                            icon={<EditOutlined />}
                            editable={true}
                            fieldType="phone_number"
                            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                        />,
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </Col>

            <Col span={6}>
                <List
                    size="small"
                    header={<Text>Shipping Address</Text>}
                    bordered
                    dataSource={[
                        ...addressText(activeOrder).split(', '),
                        <Flex gap="small" key="shipping-options" wrap>
                            <Button
                                key="0"
                                size='small'
                                type="primary"
                                ghost
                                onClick={exportToRoyalMailHandler}
                                loading={loadingObj.exportRoyalMail}
                                icon={<SendOutlined />}
                            >
                                Royal Mail
                            </Button>
                            {/* <Button
                                key="1"
                                size='small'
                                type="primary"
                                ghost
                                danger
                                onClick={exportToDPDHandler}
                                loading={loadingObj.exportDPD}
                                icon={<SiDpd />}
                            >
                                DPD
                            </Button> */}
                        </Flex>
                    ]}
                    renderItem={item => (
                        <List.Item>
                            <ListItem
                                key={0}
                                valueText={item}
                                valueCopyable={(typeof item === 'string') && (activeOrder.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                            />
                        </List.Item>
                    )}
                />
            </Col>

            {(activeOrder?.customer_address || activeOrder?.user?.company) &&
                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>Billing Address</Text>}
                        bordered
                        dataSource={[
                            ...addressText(activeOrder?.customer_address || activeOrder?.user?.company).split(', '),
                            <>
                                {activeOrder.status > 2 &&
                                    <Button
                                        key="0"
                                        size='small'
                                        type="primary"
                                        ghost
                                        onClick={getRoyalMailOrderHandler}
                                        loading={loadingObj.copyTrackingCode}
                                    >
                                        Get Royal Mail Tracking Code
                                    </Button>
                                }
                            </>
                        ]}
                        renderItem={item => (
                            <List.Item>
                                <ListItem
                                    key={0}
                                    valueText={item}
                                    valueCopyable={(typeof item === 'string') && ((activeOrder?.customer_address?.postal_code.replace(/\s/g, "") || activeOrder?.user?.company?.postal_code.replace(/\s/g, "")
                                    ) == item.replace(/\s/g, ""))}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            }

            {activeOrder.user &&
                activeOrder.user.company &&
                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>Business Information</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={0}
                                keyText="Company Name : "
                                valueText={activeOrder.user.company.name}
                                valueCopyable
                            />,
                            ...addressText(activeOrder.user.company).split(', '),
                        ]}
                        renderItem={item => (
                            <List.Item>
                                <ListItem
                                    key={0}
                                    valueText={item}
                                    valueCopyable={(typeof item === 'string') && (activeOrder.user.company?.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            }

            {activeOrder.user &&
                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>User Information</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={0}
                                keyText="Name : "
                                valueText={activeOrder.user.name}
                                valueCopyable
                            />,
                            <ListItem key={1}
                                keyText="E-Mail : "
                                valueText={activeOrder.user.email} valueCopyable
                            />,
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                </Col>
            }
        </Row>
    )
}
