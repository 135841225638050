import { Typography, Input, Button, Flex, message } from 'antd';
import React, { useState } from 'react';
import styles from '../Orders.module.scss';

const { Paragraph } = Typography;

export default function ListItem({ keyText, keyCopyable, valueText, valueCopyable, icon, onUpdate, fieldType, showPayment, showCost, onToggleDetails, activeOrder, type, bgcolor, editable }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(valueText);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const validateValue = (value) => {
        switch (fieldType) {
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    return 'Invalid email format.';
                }
                break;
            case 'phone_number':
                if (!/^\d{10,12}$/.test(value)) {
                    return 'Invalid phone number format.';
                }
                break;
            default:
                return null;
        }
    };

    const handleSaveClick = (value) => {
        const validationError = validateValue(value);
        if (validationError) {
            message.error(validationError);
            return;
        }

        setIsEditing(false);
        if (onUpdate) {
            onUpdate(fieldType, value);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditValue(valueText);
    };

    return (
        <div className={styles.ListItemWrap} >
            {keyText && <Paragraph className={styles.paragraph} copyable={keyCopyable}>
                {onToggleDetails && (
                    <span style={{ cursor: 'pointer' }} className='pr-2' type="link" onClick={onToggleDetails}>
                        {(showCost || showPayment) ? '-' : '+'}
                    </span>
                )}
                {keyText}</Paragraph>}
            <div className={`d-flex  ${styles.valueContainer}`} style={{ alignItems: 'center' }}>
                <Paragraph className={`${styles.paragraph}`} editable={editable ? {
                    onChange: (value) => handleSaveClick(value),
                } : false} copyable={valueCopyable}>
                    {type === "cost" ? `£${activeOrder?.repair_cost}` : type === "payment" ? `£${activeOrder?.deposit_amount}` : valueText}
                </Paragraph>

            </div>
            {(showCost && activeOrder) && (
                <>
                    {(type === "cost") && (
                        activeOrder?.costs.map((item, index) => (
                            <Flex
                                key={index}
                                className={`${styles.orderSummary} w-100 pb-1 ${index === activeOrder?.costs.length - 1 ? 'border-0' : 'border-bottom-dashed'}`}
                                justify='space-between'
                            >
                                <div>
                                    <p>{item?.description}</p>
                                </div>
                                <div>
                                    <p>£{item?.amount}</p>
                                </div>
                            </Flex>
                        ))
                    )}
                </>

            )}
            {(showPayment && activeOrder) && (
                <>
                    {(type === "payment") && (
                        activeOrder?.payments.map((item, index) => (
                            <Flex
                                key={index}
                                className={`${styles.orderSummary} w-100 pb-1 ${index === activeOrder?.payments.length - 1 ? 'border-0' : 'border-bottom-dashed'}`}
                                justify='space-between'
                            >
                                <div>
                                    <p>{item?.description}</p>
                                </div>
                                <div>
                                    <p>£{item?.amount}</p>
                                </div>
                            </Flex>
                        ))
                    )}
                </>

            )}
        </div>
    );
}
