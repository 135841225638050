import { Typography, Input, Button, message } from 'antd';
import React, { useState } from 'react';
import styles from '../Orders.module.scss';

const { Paragraph } = Typography;

export default function ListItem({ keyText, keyCopyable, valueText, valueCopyable, icon, onUpdate, fieldType, editable }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(valueText);

    const validateValue = (value) => {
        switch (fieldType) {
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    return 'Invalid email format.';
                }
                break;
            case 'phone_number':
                if (!/^\d{10,12}$/.test(value)) {
                    return 'Invalid phone number format.';
                }
                break;
            default:
                return null;
        }
    };

    const handleSaveClick = (value) => {
        setEditValue(value)
        const validationError = validateValue(value);
        if (validationError) {
            message.error(validationError);
            return;
        }

        setIsEditing(false);
        if (onUpdate) {
            onUpdate(fieldType, value);
        }
    };

    return (
        <div className={styles.ListItemWrap}>
            {keyText && <Paragraph className={styles.paragraph} copyable={keyCopyable}>{keyText}</Paragraph>}
            <div className={`d-flex ${styles.valueContainer}`}>
                <Paragraph
                    className={styles.paragraph}
                    editable={editable ? {
                        onChange: (value) => handleSaveClick(value),
                    } : false}
                    copyable={valueCopyable}
                >
                    {valueText}
                </Paragraph>
            </div>
        </div>
    );
}
