import { Button, Card, Col, List, Row, Space, Table, Tooltip, Typography } from 'antd';
import React, { useRef, useMemo } from 'react';

import styles from "../../Orders.module.scss";

import ListItem from '../ListItem';
import PaymentDetails from '../PaymentDetails';
import { getPassCodeOptionByValue } from '../../util/common';

const { Text } = Typography;

const columns = [
    {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        ellipsis: {
            showTitle: false,
        },
        render: (service) => (
            <Tooltip placement="topLeft" title={service}>
                {service}
            </Tooltip>
        ),
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price) => "£" + price,
        width: 100,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        width: 50,
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (_, record) => "£" + record.price * record.qty,
        width: 100,
    },
];

export default function OrderItems({ activeOrder, repairTotal, subtotal, discount, netTotal, shippingCharge, priorityCharge, baseUrl, setOpenPaymentDetails, openPaymentDetails }) {
    const orderSummeryRef = useRef(null);

    const showPaymentDetails = () => {
        orderSummeryRef.current.scrollIntoView();
        setOpenPaymentDetails(true);
    }

    const passcodeOption = useMemo(
        () => activeOrder?.passcode_option ? getPassCodeOptionByValue(activeOrder.passcode_option, !!activeOrder.passcode) : null,
        [activeOrder?.passcode_option, activeOrder?.passcode]
    );

    return (
        <>
            <Card
                title={<Text>Order Details</Text>}
                extra={
                    <Tooltip title="Check the Payments">
                        <Button danger onClick={showPaymentDetails}>Check Payment</Button>
                    </Tooltip>
                }
            >
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={18}>
                        {activeOrder.order_repair &&
                            <Table
                                key="id"
                                size='small'
                                columns={columns}
                                dataSource={activeOrder.order_repair.map(item => ({
                                    model: item.products?.model,
                                    service: item.service?.name,
                                    price: item.amount,
                                    qty: item.qty,
                                }))}
                                pagination={false}
                            />
                        }
                    </Col>
                    <Col
                        ref={orderSummeryRef}
                        span={6}
                    >
                        <List
                            size="small"
                            header={<Text>Order Summery</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0} keyText="Repair Total : " valueText={'£' + repairTotal.toFixed(2)} />,
                                <ListItem key={1} keyText="Return Shipping : " valueText={(activeOrder.order_shipment && activeOrder.order_shipment?.is_shipping === 1) ? "£" + shippingCharge : "-"} />,
                                <ListItem key={2} keyText="Priority Fee : " valueText={(activeOrder.order_priority && activeOrder.order_priority?.is_priority === 1) ? "£" + priorityCharge : "-"} />,
                                <ListItem key={3} keyText="Sub Total : " valueText={'£' + subtotal.toFixed(2)} />,
                                <ListItem key={4} keyText="Discount (£):" valueText={activeOrder.coupon ? "-£" + discount.toFixed(2) : "-"} />,
                                <ListItem key={5} keyText="Net Total : " valueText={'£' + netTotal.toFixed(2)} />,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>
                    <Col span={24}>
                        <Space.Compact block
                            direction="vertical"
                            span={24}
                            className='mt-4'
                        >
                            <Text strong>Passcode Option:</Text>
                            {passcodeOption ? (
                                <>
                                    <Text type="secondary" className={`mb-${activeOrder?.passcode_option ? 1 : 0}`}>
                                        {passcodeOption.label || '-'}
                                    </Text>
                                    <Text type="secondary">{passcodeOption.description}</Text>
                                    {passcodeOption.content &&
                                        <Text type="secondary" copyable={{ text: passcodeOption.content }}>
                                            {passcodeOption.content}
                                        </Text>
                                    }
                                </>
                            ) : (
                                <Text type="secondary" className="mb-0">-</Text>
                            )}
                            {activeOrder?.passcode && (
                                <Text>
                                    Passcode: <Text underline copyable={{ text: activeOrder.passcode }}>{activeOrder.passcode}</Text>
                                </Text>
                            )}

                            <Text className='mt-4' strong>Order Note:</Text>
                            <Text mark={!!activeOrder?.order_note}>{activeOrder?.order_note ?? "-"}</Text>
                        </Space.Compact>
                    </Col>
                </Row>
            </Card>

            <PaymentDetails
                openPaymentDetails={openPaymentDetails}
                setOpenPaymentDetails={setOpenPaymentDetails}
                activeOrder={activeOrder}
                baseUrl={baseUrl}
            />
        </>
    )
}
