import { configureStore } from '@reduxjs/toolkit';

import authReducer from "./slices/auth";
import messageReducer from "./slices/message";

import shippingOrdersReducer from './slices/shippingOrdersSlice';
import shippingOrdersRecycleReducer from './slices/shippingOrdersRecycleSlice';
import shippingDirectOrderReducer from './slices/shippingDirectOrderSlice';

import menuBadgeCountKeyReducer from './slices/menuBadgeCountKeySlice';

const reducer = {
    auth: authReducer,
    message: messageReducer,
    
    shippingOrders: shippingOrdersReducer,
    shippingOrdersRecyle: shippingOrdersRecycleReducer,
    shippingDirectOrder: shippingDirectOrderReducer,

    menuBadgeCountKey: menuBadgeCountKeyReducer,
}

export const store = configureStore({
    reducer: reducer,
    devTools: process.env.REACT_APP_ENV !== 'production',
});